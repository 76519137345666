
const getSchoolMappings = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const isDevelopment = process.env.NODE_ENV === "development";

    if (isDevelopment) {
        //FOR development - Return a static mapping
        return { "localhost": "1" };
    } else {
        // Fetch mappings from the API in production
        const response = await fetch(`${apiUrl}/api/Insts`);
        const data = await response.json();
        const mappings = {};
        data.forEach(item => {
            mappings[item.hostname] = item.code;
        });
        return mappings;
    }
};

const apiRequest = async (API_URL, method, additionalHeaders = [], body = null) => {
    try {
        const apiUrl = process.env.REACT_APP_API_URL;

        const schoolMappings = await getSchoolMappings();
        const hostname = window.location.hostname.replace('www.', '');
        const instCode = schoolMappings[hostname];

        // Base headers
        let fetchHeaders = {
            'Content-Type': 'application/json',
            'InstCode': instCode
        };

        // Additional headers provided to the function
        additionalHeaders.forEach(header => {
            fetchHeaders[header.name] = header.value;
        });

        // // Handle FormData separately
        // const isFormData = body instanceof FormData;
        // if (!isFormData && body !== null) {
        //     fetchHeaders['Content-Type'] = 'application/json';
        // }

        const response = await fetch(`${apiUrl}${API_URL}`, {
            method: method,
            headers: fetchHeaders,
            body: method === 'GET' || body === null ? null : (body instanceof FormData ? body : JSON.stringify(body))
            //body: isFormData || body === null ? body : JSON.stringify(body) // Use body directly if it's FormData or null
        });
        //console.log(`${API_URL} - ${response.status}`);

        if (response.status === 404) {
            return { data: null, error: 'Resource not found' };
        } else if (response.status === 204) {
            return { data: null, error: 'No content' };
        } else if (!response.ok) {
            const error = await response.json();
            return { data: null, error: error?.Message ? error.Message : 'Network response was not ok' };
        }

        const data = await response.json();
        return { data, error: null };

    } catch (error) {
        console.error('API request error:', error);
        return { data: null, error };
    }
};

export const fetchData = async (API_URL, transformFunction, sortFunction, additionalHeaders = []) => {
    let { data, error } = await apiRequest(API_URL, "GET", additionalHeaders);

    if (error) {
        return { data: null, error };
    }

    // If a transformFunction is provided, transform the data.
    if (transformFunction) data = data.map(transformFunction);

    // If a sortFunction is provided, sort the data.
    if (sortFunction) data = data.sort(sortFunction);

    return { data, error: null };
}

export const submitData = async (API_URL, additionalHeaders = [], bodyItems = {}, curMethod = "POST") => {
    return await apiRequest(API_URL, curMethod, additionalHeaders, bodyItems);
}

export const getHomePageUrl = async () => {
    const { data: fetchedData, error } = await fetchData('/api/InstDetails');
    return fetchedData.homePageUrl;
};
export const getPageHeader = async ({ pageName }) => {
    const additionalHeaders = pageName ? [{ name: 'PageName', value: pageName }] : [];
    const { data: fetchedData, error } = await fetchData('/api/PageHeader', null, null, additionalHeaders);

    return fetchedData.pageHeader;
};
export const getInstUtils = async () => {
    const { data: fetchedData, error } = await fetchData('/api/InstUtils');
    return fetchedData;
};
export const getInstParams = async () => {
    const { data: fetchedData, error } = await fetchData('/api/InstParams');
    return fetchedData;
};